import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { reverseUserRoute } from "routes";


// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  modSkuQuantityToOrder,
  setNewOrderInProgress,
  addFormDataToOrder,
  sendOrderOutbound,
} from "actions/orderActions";
import { fetchCustomers } from "actions/customerActions";
import { Link } from "react-router-dom";

// Material UI
import {
  makeStyles, Button, Typography, CircularProgress
} from "@material-ui/core";

// Project Components
import { Page, HeaderAndSubtitle } from "components";

// Local Components
import Products from "../components/1Products";
import Destination from "../components/2Destination";
import ItemPriceDialog from "../components/ItemPriceDialog";

import { ValidatorForm } from "react-material-ui-form-validator";

export const AddNewOutbound = (props) => {
  const classes = useStyles();
  const [orderResult, setOrderResult] = useState({});
  const [open, setOpen] = React.useState(false);
  const [pricingErrorText, setPricingErrorText] = useState("");
  const defaultSaleCurrency = "AUD" // default currency


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event) => {
    if (event.target.innerText === "CANCEL") {
      setOpen(false);
    }

    if (event.target.innerText === "SAVE") {
      skuPricingValidation(true);
    }
  };

  function skuPricingValidation(closeModal) {
    let pricingError = false;
    setPricingErrorText("");
    pendingOrderSKUs.forEach((element, index) => {

      if (!element.sale_price || 0 === element.sale_price.length || isNaN(element.sale_price) || element.sale_price < 1) {
        setPricingErrorText("Please enter a valid sale price, it should be minimum $1.");
        pricingError = true;
      }

    });
    if (closeModal && !pricingError) {
      setOpen(false);
    }

  }

  const pendingOrderSKUs = useSelector(
    (state) => state.orderReducer.newOrderItems
  );
  const newOrderObject = useSelector(
    (state) => state.orderReducer.newOrderObject
  );
  const newOrderErrors = useSelector(
    (state) => state.orderReducer.newOrderErrors
  );
  const newOrderPushing = useSelector(
    (state) => state.orderReducer.newOrderPushing
  );
  const customers = useSelector((state) => state.customersReducer.customers);
  let inputRef = React.createRef("form");

  const dispatch = useDispatch();
  const { countries, warehouses } = props;

  React.useEffect(() => {
    dispatch(setNewOrderInProgress(true, "outbound"));
    dispatch(fetchCustomers());
    dispatch(addFormDataToOrder("country", "Australia"));
  }, [dispatch]);

  const quantityVariation = (id, quantityVariation) =>
    dispatch(modSkuQuantityToOrder(id, quantityVariation));

  const DestinationInputs = [

    {
      id: "warehouse_id",
      label: "KeepSpace Warehouse",
      required: true,
      type: "native-select",
      options: warehouses,
    },
    {
      id: "contact_name",
      label: "Contact Name",
      required: true,
    },
    {
      id: "company_name",
      label: "Company Name",
      required: true,
    },
    {
      id: "contact_phone",
      label: "Phone Number",
      required: false,
    },
    {
      id: "address",
      label: "Address line 1",
      required: true,
    },
    {
      id: "address2",
      label: "Address line 2",
      required: false,
    },
    {
      id: "suburb",
      label: "Suburb",
      required: true,
    },
    {
      id: "city",
      label: "Postcode",
      required: true,
    },
    {
      id: "state",
      label: "State",
      required: true,
    },
    {
      id: "country",
      label: "Country",
      required: true,
      type: "native-select",
      options: countries,
    },
    {
      id: "email",
      label: "Email",
      required: true,
    },
    {
      id: "reference",
      label: "Reference / Order Number",
      required: true,
    },
    {
      id: "shipping_method",
      label: "Shipping Method",
      required: true,
      type: "native-select",
      options: [
        {
          value: "AusPost STD 3-7 Business Days",
          text: "AusPost STD 3-7 Business Days"
        },
        {
          value: "AusPost STD + Sign 3-7 Business Days",
          text: "AusPost STD + Sign 3-7 Business Days"
        },
        {
          value: "AusPost STD + Insured + Sign 5-8 Business Days",
          text: "AusPost STD + Insured + Sign 5-8 Business Days"
        },
        {
          value: "AusPost EXP 2-5 Business Days",
          text: "AusPost EXP 2-5 Business Days"
        },
        {
          value: "AusPost EXP + Sign 2-5 Business Days",
          text: "AusPost EXP + Sign 2-5 Business Days"
        },
        {
          value: "AusPost EXP + Insured + Sign 2-5 Business Days",
          text: "AusPost EXP + Insured + Sign 2-5 Business Days"
        },
        {
          value: "AusPost INT STD 6-10 Business Days",
          text: "AusPost INT STD 6-10 Business Days"
        },
        {
          value: "AusPost INT STD + Insured 6-10 Business Days",
          text: "AusPost INT STD + Insured 6-10 Business Days"
        },
        {
          value: "AusPost INT EXP 3-7 Business Days",
          text: "AusPost INT EXP 3-7 Business Days"
        },
        {
          value: "AusPost INT EXP + Insured 3-7 Business Days",
          text: "AusPost INT EXP + Insured 3-7 Business Days"
        },
        {
          value: "DHL EXP 2-5 Business Days",
          text: "DHL EXP 2-5 Business Days"
        },
        {
          value: "FedEx EXP 2-5 Business Days",
          text: "FedEx EXP 2-5 Business Days"
        },
        {
          value: "TNT Road 3-7 Business Days",
          text: "TNT Road 3-7 Business Days"
        },
        {
          value: "TNT Overnight 1-2 Business Days",
          text: "TNT Overnight 1-2 Business Days"
        },
        {
          value: "TNT SDAY 3-5 hours",
          text: "TNT SDAY 3-5 hours"
        },
        {
          value: "StarTrack Road 3-7 Business Days",
          text: "StarTrack Road 3-7 Business Days"
        },
        {
          value: "StarTrack Overnight Sign 1-2 Business Days",
          text: "StarTrack Overnight Sign 1-2 Business Days"
        },
        {
          value: "StarTrack Overnight 1-2 Business Days",
          text: "StarTrack Overnight 1-2 Business Days"
        },
        {
          value: "Pickup 2-4pm",
          text: "Pickup 2-4pm"
        },
        {
          value: "Domestic letters",
          text: "Domestic letters"
        }
      ],
    },
    {
      id: "comment",
      label: "Comments",
      required: false,
    },
  ];

  const AutosuggestFields = {
    display: {
      title: {
        label: "Name: ",
        value: "name",
      },
      subtitle: [
        {
          label: "Company name: ",
          value: "company_name",
        },
      ],
    },
    // match: ['company_name', 'name'],
    match: ["name"],
    value: "name",
    placeholder: "Search customers",
  };

  const handleSelectCustomer = (customer) => {
    //excluding warehouse from the fields that needs to be changed based upon selected customer;
    const fields = DestinationInputs.slice(1).map((h) => h.id);
      // storing supplier_id in newOrderObject;
      dispatch(addFormDataToOrder('customer_id', customer?.id));

    fields.forEach((key) => {
      switch (key) {
        case "contact_name":
          dispatch(addFormDataToOrder(key, customer.name));
          break;
        case "city":
          dispatch(addFormDataToOrder(key, customer.postcode));
          break;
        case "address":
          dispatch(addFormDataToOrder(key, customer.address_line_1));
          break;
        case "address2":
          dispatch(addFormDataToOrder(key, customer.address_line_2));
          break;
        case "contact_phone":
          dispatch(addFormDataToOrder(key, customer.phone));
          break;
        case "shipping_method":
          break;
        case "reference":
          break;
        default:
          dispatch(addFormDataToOrder(key, customer[key]));
          break;
      }
    });
  };

  const handleChange = (event, id, value) => {
    const fieldId = (event) ? event.target.id : id;
    const val = (event) ? event.target.value : value;

    dispatch(addFormDataToOrder(fieldId, val));
    if (fieldId === "country" && international_order(val) && pendingOrderSKUs.length) {
      handleClickOpen();
    }
  };

  const international_order = country => {
    return country && !['AUSTRALIA', 'AU', 'AUS', 'AUSTR', '', '.', '..'].includes(country.toUpperCase())
  }

  function itemPricingChange(value, id, type) {

    pendingOrderSKUs.forEach((element, index) => {
      if (element.item.id === id) {

        if (type === "price") {
          element.sale_price = value || element.price;
        }
        dispatch(modSkuQuantityToOrder(id, 0, element.sale_price, element.sale_currency || defaultSaleCurrency));
      }
    });
    skuPricingValidation();
  };

  const saleDetails = (item, internationalOrder) =>
    internationalOrder ?
      {
        sale_currency: item.sale_currency,
        sale_price: item.sale_price || ''
      } : {}

  const submit = (event) => {
    event.preventDefault();
    let booking = newOrderObject;
    let internationalOrder = international_order(booking.country)
    let isValidObj = true;
    let items = pendingOrderSKUs.map((pendingOrderSKU) => ({
      id: pendingOrderSKU.item.id,
      quantity: pendingOrderSKU.quantity,
      sku: pendingOrderSKU.item.sku,
      ...saleDetails(pendingOrderSKU, internationalOrder)
    }));

    if (internationalOrder) {
      items.forEach((bookingItem) => {
        if (!bookingItem.sale_price) {
          setPricingErrorText("Please provide the missing values");
          isValidObj = false;
        }
      });
    }

    if (isValidObj) {
      dispatch(sendOrderOutbound(booking, items)).then((result) => {
        if ((result) && pendingOrderSKUs.length) {
          setOrderResult(result);
        }
      });
    } else {
      handleClickOpen();
    }

  };

  return (
    <Page title="Add New Outbound" className={classes.root}>
      {orderResult.id ? (
        <Redirect
          to={`${reverseUserRoute("Orders")}summary/${orderResult.id}/details/`}
        />
      ) : null}
      <HeaderAndSubtitle
        header="Order / Summary"
        subtitle="New Outbound Order"
      />

      <Products
        key="products"
        pendingOrderSKUs={pendingOrderSKUs}
        quantityVariation={quantityVariation}
        orderType="outbound"
        pricingModal={handleClickOpen}
        formState={newOrderObject}
      />

      <ValidatorForm
        onSubmit={submit}
        onError={(errors) => console.log(errors)}
        ref={inputRef}
      >
        <Destination
          key="destination"
          formInputs={DestinationInputs}
          formState={newOrderObject}
          formErrors={newOrderErrors}
          onChange={handleChange}
          orderType="outbound"
          searchInput={customers}
          handleSelect={handleSelectCustomer}
          autosuggestFields={AutosuggestFields}
          {...props}
        />

        {newOrderErrors ? (
          <Typography color="error">{newOrderErrors.messages || newOrderErrors}</Typography>
        ) : null}

        {/* Submit */}
        <Button
          variant="contained"
          color="secondary"
          to={reverseUserRoute("Orders:Summary")}
          component={Link}
          style={{ marginRight: 10 }}
          disabled={newOrderPushing}
        >
          <Typography color="inherit">Cancel</Typography>
        </Button>
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={newOrderPushing || pendingOrderSKUs.length === 0}
          >
            <Typography color="inherit">Create Order</Typography>

          </Button>
          {newOrderPushing && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </ValidatorForm>

      <ItemPriceDialog open={open} items={pendingOrderSKUs} handleClose={handleClose}
        itemPricingChange={itemPricingChange} pricingErrorText={pricingErrorText} defaultSaleCurrency={defaultSaleCurrency} />
    </Page>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    display: 'inline'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default AddNewOutbound;
