const customerEditFormFields = ({countries}) =>(
  [
    {
      value: 'name',
      label: 'Name *',
      required: ['required'],
      errorMessages: ['this field is required'],
      options: [],
    },
    { value: 'company_name', label: 'Company Name', required: [], options: [] },
    {
      value: 'email',
      label: 'Email address *',
      required: ['required', 'isEmail'],
      errorMessages: ['this field is required', 'email is not valid'],
      options: [],
    },
    {
      value: 'phone',
      label: 'Phone',
      required: ['required', 'minStringLength:8', 'maxStringLength:15'],
      errorMessages: ['this field is required', 'Number is not valid', 'Number is not valid'],
      options: [],
    },
    {
      value: 'address_line_1',
      label: 'Address 1 *',
      required: ['required'],
      errorMessages: ['this field is required'],
      options: [],
    },
    { value: 'address_line_2', label: 'Address 2', required: [], options: [] },
    {
      value: 'postcode',
      label: 'Postcode *',
      required: ['required'],
      errorMessages: ['this field is required'],
      options: [],
    },
    {
      value: 'suburb',
      label: 'Suburb *',
      required: ['required'],
      errorMessages: ['this field is required'],
      options: [],
    },
    {
      value: 'state',
      label: 'State/Region *',
      required: ['required'],
      errorMessages: ['this field is required'],
      options: [],
    },
    {
      value: 'country',
      label: 'Country *',
      required: ['required'],
      errorMessages: ['required'],
      options: countries,
      select: true,
      optionValue: 'text',
    },
    { value: 'customer_type',
      label: 'Type', required: [],
      options: ['', 'Wholesale', 'Retail'],
      select: true
    },

  ]);


export default customerEditFormFields;
