import { adminApiGenPath, adminApiPath, adminAuthHeaders } from 'AppConstants';
import axios from 'axios';
import ApiService, { HEADERS } from '../Api';

const BASE_URL = adminApiPath;
const BASE_URL_GEN = adminApiGenPath;

const defaultHeaders = {
  ...HEADERS,
  ...adminAuthHeaders,
  'KS-APP': 'ADMIN', //picker, packer, admin
  'KS-API-VERSION': '1',
  'KS-APP-VERSION': '1',
};

let headers = defaultHeaders;


let client = new ApiService({ baseURL: BASE_URL, headers });
let clientGen = new ApiService({ baseURL: BASE_URL_GEN, headers });
const PAGE_LIMIT = 25;
const ARGS = `?per_page=${PAGE_LIMIT}`;

const adminApi = {};

/**
 * Public Methods for the KeepSpace ADMIN API
 *
 * Example API Server can be found at [production-api.keepspace.com.au]
 * Authentication is based on the JWT standard, but sent as sepereate headers
 */

adminApi.postLogin = (email, password) => client.postWithHeaders(`auth/sign_in`, { email, password });
adminApi.postLogout = (id) => client.delete(`auth/sign_out`, { id });
adminApi.getAdminUserValidate = (email, password) => client.getWithHeaders(`admin_users/validate`, { email, password });

// SKU
adminApi.fetchSku = (args) => client.get(`items`, args);
adminApi.fetchSkuFilters = (args) => client.get(`items/filters`, args);
adminApi.fetchItemById = (id) => client.get(`items/${id}`);
adminApi.fetchItemHistory = (id, params = {}) => client.get(`items/${id}/history`, params);
adminApi.fetchMovementByItemId = (id, params) => client.get(`items/${id}/movement`, params);
adminApi.fetchTypesByItemId = (id) => client.get(`items/${id}/types`);
adminApi.fetchSkuOrders = (id, param) => client.get(`items/${id}/orders`, param);
adminApi.archivedSKUType = (id) => client.delete(`items/${id}`);
adminApi.breakDownSKUType = (id, params) => client.post(`items/${id}/break_into_units`, params);
adminApi.getParentSku = (id, data) => client.get(`items/${id}/new_type`, data);
adminApi.createSkuType = (id, data) => client.post(`items/${id}/create_types`, data);

adminApi.updateSkuType = (id, data) => client.patch(`items/${id}`, data);
adminApi.changeSkuType = (id, data) => client.patch(`items/${id}/change_sku_type`, data);

adminApi.archivedSKU = (id) => client.delete(`items/${id}`);
adminApi.unarchivedSKU = (id) => client.post(`items/${id}/unarchive`);
adminApi.updateItem = (data, id) => client.patch(`items/${id}`, data);
adminApi.updateItemLocation = (data, id) => client.patch(`items/${id}/update_location`, data);
adminApi.addItemLocation = (data, id) => client.post(`items/${id}/add_location`, data);
adminApi.deleteItemLocation = (data, id) => client.post(`items/${id}/remove_location`, data);
adminApi.getSkuLocations = (term, warehouseId) => clientGen.get(`search-suggestions/location-by-row?warehouse_id=${warehouseId}&term=${term}`);
adminApi.getLocationsByBarcode = (term, warehouseId) => clientGen.get(`search-suggestions/location-by-barcode?warehouse_id=${warehouseId}&term=${term}`);
adminApi.updateItemImages = (data, id) => client.patch(`items/${id}/add_images`, data);

adminApi.createItem = (data) => client.post(`items/`, data);
adminApi.printSkuBarcodes = (item) => client.downloadFile(`items/print_barcodes`, item);
adminApi.searchSkuSuggestionByOrg = (term, orgId) => clientGen.get(`search-suggestions/skus-by-org-term?term=${term}&organisation_id=${orgId}`);
adminApi.searchSkuSuggestion = (term) => clientGen.get(`search-suggestions/skus-by-term?term=${term}`);
adminApi.addSkuStock = (id, data) => client.post(`items/${id}/add_stock`, data);
adminApi.stockCheck = (id) => client.post(`items/${id}/stock_check`);

// Bundles
adminApi.fetchBundles = (args) => client.get(`bundles`, args);
adminApi.fetchBundlesFilters = (args) => client.get(`bundles/filters`, args);
adminApi.fetchBundleById = (id) => client.get(`bundles/${id}`);
adminApi.updateBundleById = (data, id) => client.patch(`bundles/${id}`, data);
adminApi.createBundle = (item) => client.post(`bundles/`, item);
adminApi.deleteBundle = (id) => client.delete(`bundles/${id}`);
adminApi.unarchiveBundle = (id) => client.post(`bundles/${id}/unarchive`);

//Handlings
adminApi.fetchPacking = (wid) => client.get(`warehouses/${wid}/handlings`);
adminApi.deletePacking = (id, wid) => client.delete(`warehouses/${wid}/handlings/${id}`);
adminApi.getPackingDetail = (id, wid) => client.get(`warehouses/${wid}/handlings/${id}`);
adminApi.updatePacking = (item, wid) => client.patch(`warehouses/${wid}/handlings/${item.id}`, item);
adminApi.createPacking = (item, wid) => client.post(`warehouses/${wid}/handlings`, item);

//Prepping
adminApi.fetchPrepping = (wid) => client.get(`warehouses/${wid}/preppings`);
adminApi.deletePrepping = (id, wid) => client.delete(`warehouses/${wid}/preppings/${id}`);
adminApi.getPreppingDetail = (id, wid) => client.get(`warehouses/${wid}/preppings/${id}`);
adminApi.updatePrepping = (item, wid) => client.patch(`warehouses/${wid}/preppings/${item.id}`, item);
adminApi.createPrepping = (item, wid) => client.post(`warehouses/${wid}/preppings`, item);

//Administration
adminApi.fetchAdministration = (wid) => client.get(`warehouses/${wid}/administration_costs`);
adminApi.deleteAdministration = (id, wid) => client.delete(`warehouses/${wid}/administration_costs/${id}`);
adminApi.getAdministrationDetail = (id, wid) => client.get(`warehouses/${wid}/administration_costs/${id}`);
adminApi.updateAdministration = (item, wid) => client.patch(`warehouses/${wid}/administration_costs/${item.id}`, item);
adminApi.createAdministration = (item, wid) => client.post(`warehouses/${wid}/administration_costs`, item);

//Inbound
adminApi.fetchInbound = (wid) => client.get(`warehouses/${wid}/inbound_charges`);
adminApi.deleteInbound = (id, wid) => client.delete(`warehouses/${wid}/inbound_charges/${id}`);
adminApi.getInboundDetail = (id, wid) => client.get(`warehouses/${wid}/inbound_charges/${id}`);
adminApi.updateInbound = (item, wid) => client.patch(`warehouses/${wid}/inbound_charges/${item.id}`, item);
adminApi.createInbound = (item, wid) => client.post(`warehouses/${wid}/inbound_charges`, item);

//Picking
adminApi.fetchPicking = (wid) => client.get(`warehouses/${wid}/picks`);
adminApi.deletePicking = (range, wid) => client.patch(`warehouses/${wid}/picks/batch_archive`, range);
adminApi.getPickingDetail = (range, wid) => client.get(`warehouses/${wid}/picks/show_by_weight_range`, range);
adminApi.createPicking = (item, wid) => client.post(`warehouses/${wid}/picks/batch_create`, item);
adminApi.fetchPickTiers = (data, wid) => client.get(`warehouses/${wid}/picks/margins`, data);

//Parcel Material
//adminApi.fetchMaterial= (args) => client.get(`parcel_materials`,{args})
adminApi.deleteMaterial = (id) => client.delete(`parcel_materials/${id}`);
adminApi.getMaterialDetail = (id) => client.get(`parcel_materials/${id}`);
adminApi.updateMaterial = (id, item) => client.patch(`parcel_materials/${id}`, item);
adminApi.createMaterial = (item) => client.post(`parcel_materials`, item);
adminApi.fetchMaterial = (args) => client.get(`parcel_materials`, args);
adminApi.fetchMaterialFilters = (args) => client.get(`parcel_materials/filters`, args);
adminApi.getMovements = (id, params) => client.get(`parcel_materials/${id}/get_parcel_material_stocks`, params);
adminApi.addMaterialStock = (id, data) => client.post(`parcel_materials/${id}/add_parcel_material_stocks`, data);
adminApi.addInventoryLocation = (data, id) => client.post(`parcel_materials/${id}/add_location`, data);
adminApi.deleteInventoryLocation = (data, id) => client.post(`parcel_materials/${id}/remove_location`, data);

//Other - Extra Charges

adminApi.deleteDuties = (id) => client.delete(`organisation_extra_charges/${id}`);
adminApi.getDutiesDetail = (id) => client.get(`organisation_extra_charges/${id}`);
adminApi.updateDuties = (id, item) => client.patch(`organisation_extra_charges/${id}`, item);
adminApi.createDuties = (item) => client.post(`organisation_extra_charges`, item);
adminApi.fetchDuties = (args) => client.get(`organisation_extra_charges`, args);
adminApi.fetchDutiesFilters = (args) => client.get(`organisation_extra_charges/filters`, args);
adminApi.getBookingByReference = (id, args) => client.get(`organisations/${id}/find_booking_by_reference`, args);

//Batches
adminApi.fetchBatches = (filters) => client.get(`batches?${filters}`);
adminApi.fetchBatchesFilters = (filters) => client.get(`batches/filters`);
adminApi.createBatch = (batch) => client.post(`batches`, batch);
adminApi.fetchAvailableTrolleys = (batch_id) => client.get(`batch_trolleys/available_trolleys?batch_id=${batch_id}`);
adminApi.createBatchTrolley = (batchTrolley) => client.post(`batch_trolleys`, batchTrolley);
adminApi.fetchBatchBookings = (filters) => client.get(`batch_bookings`, filters);
adminApi.updateStockInfo = (stockInfo) => client.post(`batch_bookings/skus_stock_info`, stockInfo);
adminApi.selectOrder = (batchTrolleyItem) => client.post(`batch_trolley_slot_booking_items`, batchTrolleyItem);
adminApi.deleteSelectOrder = (batchTrolleyItem) => client.delete(`batch_trolley_slot_booking_items/delete`, batchTrolleyItem);
adminApi.fetchSlots = (id) => client.get(`batch_trolleys/${id}`);
adminApi.fetchBatchCreated = (id) => client.get(`batches/${id}`);
adminApi.updateStatuses = (id, updateBatch) => client.patch(`batches/${id}`, updateBatch);
adminApi.fetchPickerPacker = () => client.get(`admin_users/pickers_packers`);

//Orders
adminApi.fetchAdminOrders = (param) => client.get(`bookings`, param);
adminApi.fetchAdminOrdersFilters = (param) => client.get(`bookings/filters`, param);
adminApi.fetchOrderSummary = (id) => client.get(`bookings/${id}/summary`);
adminApi.fetchOrderHistory = (id) => client.get(`bookings/${id}/history`);
adminApi.fetchOrderSKU = (id) => client.get(`bookings/${id}/skus`);
adminApi.fetchShippingOrder = (id) => client.get(`bookings/${id}/shipment`);
adminApi.fetchInboundLabel = (id) => client.get(`bookings/${id}/shipment_label`);
adminApi.fetchInboundBoxes = (id) => client.get(`bookings/${id}/shipment_boxes`);
adminApi.fetchInboundReport = (id) => client.get(`bookings/${id}/shipment_report`);
adminApi.printInboundLabel = (id) => client.downloadFile(`bookings/${id}/print_shipment_label`);
adminApi.fetchSuggestedCarriers = (id) => client.get(`bookings/${id}/suggested_carriers`);
adminApi.fetchOrderChat = (id) => client.get(`bookings/${id}/chat`);
adminApi.createOrderChat = (id, data) => client.post(`bookings/${id}/chat`, data);
adminApi.fetchOrderPricing = (id) => client.get(`bookings/${id}/pricing`);
adminApi.updateOrderPricing = (id, item) => client.patch(`bookings/${id}/pricing`, item);
adminApi.updateOrderNote = (id, data) => client.patch(`bookings/${id}/note`, data);
adminApi.downloadAUCSV = (data) => client.post(`bookings/download_au_csvs`, data);
adminApi.orderBulkUpdate = (data) => client.patch(`bookings/bulk_update`, data);
adminApi.orderProcessBulkUpdate = (data) => client.patch(`bookings/bulk_process`, data);
adminApi.updateOrderShipment = (id, data) => client.patch(`bookings/${id}/shipment`, data);
adminApi.updateInboundOrderShipment = (id, data) => client.patch(`bookings/${id}/edit_inbound`, data);
adminApi.updateOrderSKUs = (id, data) => client.patch(`bookings/${id}/skus`, data);
adminApi.addInternalComment = (id, data) => client.post(`bookings/${id}/comment`, data);
adminApi.updateSerialNumber = (id, data) => client.patch(`bookings/${id}/skus_serial_numbers`, data);
adminApi.createOrder = (id, data) => client.post(`organisations/${id}/create_order`, data);
adminApi.selectCarrier = (id, data) => client.post(`bookings/${id}/select_shipper_service`, data);
adminApi.cancelLabel = (id) => client.post(`bookings/${id}/cancel_label`);
adminApi.removeCarrier = (id) => client.post(`bookings/${id}/remove_shipper`);
adminApi.createCustomCarrier = (id, data) => client.post(`bookings/${id}/custom_carrier`, data);
adminApi.checkJoBStatus = (jobId) => client.get(`/job-status`, {job_uid: jobId})

//labels APIs
adminApi.viewShippingLabel = (id, data) => client.post(`bookings/${id}/view_label`, data);
adminApi.printLabel = (id, data, slug, is_internal_label) => is_internal_label ?
client.downloadFile(`bookings/${id}/${slug}`, data) : client.post(`bookings/${id}/${slug}`, data);

adminApi.unProcessOrder = (id) => client.patch(`bookings/${id}/unprocess`);
adminApi.getOrderParcels = (id) => client.get(`bookings/${id}/parcels`);
adminApi.updateOrderParcels = (id, data) => client.patch(`bookings/${id}/parcels`, data);


let CancelToken = axios.CancelToken;
let source = CancelToken.source();
let token = null;


adminApi.suggestHandlings = (id, data) => {

  if (token) {
    token = null;
    source.cancel('Operation canceled by the user.');
    source = CancelToken.source();
  }
  token = source.token;
  let callback = () => {
    token = null;
    source = CancelToken.source();
  }

  return client.postWithConfig(`bookings/${id}/suggest_handling`, data, { cancelToken: token }, callback)
};


adminApi.getBookingItemDetail = (id, itemId) => client.get(`bookings/${id}/item_details/${itemId}`);
adminApi.validateAddress = (data) => client.post(`bookings/validate_address`, data);
adminApi.fetchBatchDetail = (id) => client.get(`bookings/${id}/batch_details`);
adminApi.archiveComment = (id, data) => client.post(`/bookings/${id}/archive_chat`, data);
adminApi.orderUpdateWarehouse = (id, params) => client.patch(`/bookings/${id}/change_warehouse`, params);

//AdminOrganisations
adminApi.fetchAdminOrganisations = (params) => client.get(`organisations`, params)
adminApi.deleteAdminOrganisations = (id) => client.delete(`organisations/${id}`)
adminApi.getAdminOrganisationDetail = (id) => client.get(`organisations/${id}`)
adminApi.updateAdminOrganisation = (item, id) => client.patch(`organisations/${id}`, item)
adminApi.createAdminOrganisation = (item) => client.post(`organisations`, item)
adminApi.fetchOrgBundles = (id, args) => client.get(`organisations/${id}/bundles`, args);
adminApi.fetchOrgOrders = (id, param) => client.get(`organisations/${id}/orders`, param);
adminApi.fetchOrgInvoices = (id, param) => client.get(`organisations/${id}/invoices`, param);
adminApi.fetchOrganisationHistory = (id, params = {}) => client.get(`organisations/${id}/history`, params);

adminApi.downloadSKUTmpl = (id, is_create) => client.get(`organisations/${id}/products_template?is_create=${is_create}`);
adminApi.uploadSKUTmpl = (id, data, is_create) => client.post(`organisations/${id}/upload_products_csv?is_create=${is_create}`, data);

adminApi.downloadOrderTmpl = (id) => client.get(`organisations/${id}/orders_template`);
adminApi.uploadOrderTmpl = (id, data) => client.post(`organisations/${id}/upload_orders_csv`, data);

adminApi.downloadBundleTmpl = (id) => client.get(`organisations/${id}/bundles_template`);
adminApi.uploadBundleTmpl = (id, data) => client.post(`organisations/${id}/upload_bundles_csv`, data);

adminApi.downloadBundleCompTmpl = (id) => client.get(`organisations/${id}/bundle_components_template`);
adminApi.uploadBundleCompTmpl = (id, data) => client.post(`organisations/${id}/upload_bundle_components_csv`, data);

//AdminUsers
adminApi.fetchAdminOrgUsers = (params) => client.get(`users`, params);
adminApi.deleteAdminOrgUsers = (id) => client.delete(`users/${id}`);
adminApi.getAdminOrgUserDetail = (id) => client.get(`users/${id}`);
adminApi.updateAdminOrgUser = (orgUser, id) => client.patch(`users/${id}`, orgUser);
adminApi.magicLogin = (id) => client.get(`users/${id}/magic_login`);


//Terminologies
adminApi.fetchTerminologies = (orgId) => client.get(`organisations/${orgId}/terminologies`);
adminApi.deleteTerminologies = (id) => client.delete(`terminologies/${id}`);
adminApi.getTerminologyDetail = (id) => client.get(`terminologies/${id}`);
adminApi.updateTerminology = (id, item) => client.patch(`terminologies/${id}`, item);
adminApi.createTerminology = (orgId, item) => client.post(`organisations/${orgId}/terminologies`, item);

//Orderd Quantities
adminApi.fetchOrderedQuantities = (orgId, params) => client.get(`organisations/${orgId}/ordered_quantities`, params);

//Integration
adminApi.fetchIntegrations = (orgId) => client.get(`organisations/${orgId}/app_integrations`);
adminApi.deleteIntegrations = (orgId, id) => client.delete(`organisations/${orgId}/app_integrations/${id}`);
adminApi.getIntegrationDetail = (orgId, id) => client.get(`organisations/${orgId}/app_integrations/${id}`);
adminApi.updateIntegrations = (orgId, id, item) => client.patch(`organisations/${orgId}/app_integrations/${id}`, item);
adminApi.createIntegration = (orgId, item) => client.post(`organisations/${orgId}/app_integrations`, item);


//Suppliers
adminApi.fetchSuppliers = (params) => client.get(`suppliers`, params);
adminApi.deleteSuppliers = (id) => client.delete(`suppliers/${id}`);
adminApi.getSupplierDetail = (id) => client.get(`suppliers/${id}`);
adminApi.updateSupplier = (item, id) => client.patch(`suppliers/${id}`, item);
adminApi.createSupplier = (item) => client.post(`suppliers`, item);
adminApi.getSupplierDetailByName = (param) => clientGen.get(`search-suggestions/admin-supplier-by-name?term=${param}`);
adminApi.fetchOrgSuppliers = (orgId, param) => client.get(`organisations/${orgId}/suppliers`, param);

//Wallet
adminApi.fetchTransactions = (params, id) => client.get(`organisations/${id}/organisation_wallet_transactions/`, params);
adminApi.createTransaction = (params, id) => client.post(`organisations/${id}/organisation_wallet_transactions/`, params);

//Warehouse
adminApi.fetchWarehouse = (params) => client.get(`warehouses`, params);
adminApi.getWarehousesDetail = (id) => client.get(`warehouses/${id}`);
adminApi.updateWarehouse = (id, item) => client.patch(`warehouses/${id}`, item);
adminApi.createWarehouse = (item) => client.post(`warehouses`, item);
adminApi.getLocations = (id, params) => client.get(`warehouses/${id}/locations`, params);
adminApi.getLocationDetail = (wid, id) => client.get(`warehouses/${wid}/locations/${id}`);
adminApi.createLocation = (wid, item) => client.post(`warehouses/${wid}/locations`, item);
adminApi.updateLocation = (wid, id, item) => client.patch(`warehouses/${wid}/locations/${id}`, item);
adminApi.printBarcodes = (wid, item) => client.downloadFile(`warehouses/${wid}/locations/print_barcodes`, item);
adminApi.getDataToAddWarehouse = () => client.get(`warehouses/new`);
adminApi.deleteWarehouse = (id) => client.delete(`warehouses/${id}`);

//Shippers
adminApi.fetchShippers = (params, wid, id) => client.get(`warehouses/${wid}/shipper_services?shipper_id=${id}`, params);
adminApi.updateShipper = (item, id, wid) => client.patch(`warehouses/${wid}/shipper_services/${id}`, item);
adminApi.createShipper = (item, wid) => client.post(`warehouses/${wid}/shipper_services`, item);
adminApi.deleteShipper = (id, wid) => client.delete(`warehouses/${wid}/shipper_services/${id}`);
adminApi.createShipperService = (item, shipperId, wid) => client.post(`warehouses/${wid}/shipper_services/`, item);
adminApi.updateShipperService = (wid, shipperId, data) => client.patch(`warehouses/${wid}/shipper_services/${shipperId}`, data);
adminApi.deleteShipperService = (shipperId, id, wid) => client.delete(`warehouses/${wid}/shipper_services/${id}`);
adminApi.updateManagerDetails = (values, id, wid) => client.patch(`warehouses/${wid}/shippers/${id}/update_manager`, values);
adminApi.fetchEParcelManifests = (wid, sid, params) => client.get(`warehouses/${wid}/shippers/${sid}/eparcel_manifests`, params);
adminApi.fetchEParcelManifestsPDF = (wid, sid, mid) => client.downloadFile(`warehouses/${wid}/shippers/${sid}/eparcel_manifests/${mid}/get_pdf`);

//Setting/shipping
adminApi.fetchAccShippers = (wid) => client.get(`warehouses/${wid}/shippers/`);
adminApi.fetchTiers = (shipperId, wid) => client.get(`warehouses/${wid}/shippers/${shipperId}/calculator/`);
adminApi.runReport = (params, shipperId, wid) => client.post(`warehouses/${wid}/shippers/${shipperId}/run_report/`, params);
adminApi.testReport = (params, shipperId, wid) => client.post(`warehouses/${wid}/shippers/${shipperId}/get_shipping_price/`, params);

adminApi.fetchZones = (shipperId, wid) => client.get(`warehouses/${wid}/shippers/${shipperId}/shipper_zones/`);
adminApi.getZonesDetail = (shipperId, id, wid) => client.get(`warehouses/${wid}/shippers/${shipperId}/shipper_zones/${id}`);
adminApi.updateZone = (shipperId, id, data, wid) => client.patch(`warehouses/${wid}/shippers/${shipperId}/shipper_zones/${id}`, data);
adminApi.createZones = (shipperId, data, wid) => client.post(`warehouses/${wid}/shippers/${shipperId}/shipper_zones/`, data);
adminApi.fetchCountryByName = (data, wid = null) => clientGen.get(`search-suggestions/country-by-name`, data);

adminApi.fetchShipperServicePrices = (shipperId, wid) => client.get(`warehouses/${wid}/shipper_services/${shipperId}/shipper_service_prices/`);
adminApi.fetchShipperServiceMargins = (shipperId, data, wid) => client.get(`warehouses/${wid}/shipper_services/${shipperId}/shipper_service_margins/`, data);
adminApi.createUpdateShipperServicePrice = (shipperId, data, wid) => client.post(`warehouses/${wid}/shipper_services/${shipperId}/shipper_service_prices/`, data);
adminApi.createUpdateShipperServiceMargin = (shipperId, data, wid) => client.post(`warehouses/${wid}/shipper_services/${shipperId}/shipper_service_margins/`, data);
adminApi.updateShipperServicePrices = (wid, shipperId, data) => client.patch(`warehouses/${wid}/shipper_services/${shipperId}/shipper_service_prices/batch_update`, data);
adminApi.updatePickerServicePrices = (wid, data) => client.patch(`warehouses/${wid}/picks/batch_update`, data);
adminApi.updatePickerServiceMargins = (wid, data) => client.patch(`warehouses/${wid}/picks//margins_batch_update`, data);
adminApi.updateShipperServiceMargins = (wid, shipperId, data) => client.patch(`warehouses/${wid}/shipper_services/${shipperId}/shipper_service_margins/batch_update`, data);

//Zones
adminApi.fetchWHZones = () => client.get(`zones/`);
adminApi.getWHZonesDetail = (id) => client.get(`zones/${id}`);
adminApi.updateWHZone = (id, data) => client.patch(`zones/${id}`, data);
adminApi.createWHZone = (data) => client.post(`zones/`, data);
adminApi.deleteWHZone = (id) => client.delete(`zones/${id}`);

//Priority
adminApi.fetchPriority = (params) => client.get(`zone_warehouses`, params);
adminApi.getPrioritysDetail = (id) => client.get(`zone_warehouses/show_by_zone?zone_id=${id}`);
adminApi.updatePriority = (id, item) => client.patch(`zone_warehouses/${id}`, item);

//AdminUsers
adminApi.fetchAdminUsers = (params) => client.get(`admin_users`, params);
adminApi.deleteAdminUsers = (id) => client.delete(`admin_users/${id}`);
adminApi.getAdminUserDetail = (id) => client.get(`admin_users/${id}`);
adminApi.updateAdminUser = (item, id) => client.patch(`admin_users/${id}`, item);
adminApi.createAdminUser = (item) => client.post(`admin_users`, item);

//Trolleys
adminApi.fetchTrolleys = (params, wid) => client.get(`warehouses/${wid}/trolleys`, params);
adminApi.getTrolleyDetail = (id, wid) => client.get(`warehouses/${wid}/trolleys/${id}`);
adminApi.updateTrolley = (item, id, wid) => client.patch(`warehouses/${wid}/trolleys/${id}`, item);
adminApi.createTrolley = (item, wid) => client.post(`warehouses/${wid}/trolleys`, item);
adminApi.deleteTrolley = (id, wid) => client.delete(`warehouses/${wid}/trolleys/${id}`);
adminApi.duplicateTrolley = (id, item, wid) => client.post(`warehouses/${wid}/trolleys/${id}/duplicate`, item);
adminApi.printTrolleyBarcodes = (item, wid) => client.downloadFile(`warehouses/${wid}/trolleys/print_barcodes`, item);

//Teams
adminApi.fetchTeams = (wid) => client.get(`warehouses/${wid}/team/`);
//Invoices
adminApi.fetchInvoices = (params) => client.get(`invoices`, params);
adminApi.fetchDraftInvoices = (params) => client.get(`invoices/draft_invoices`, params);
adminApi.cancelInvoice = (id) => client.post(`invoices/${id}/cancel`);
adminApi.fetchInvoiceById = (id) => client.get(`invoices/${id}`);
adminApi.updateQuickBookUrl = (id, data) => client.post(`invoices/${id}/add_attachment`, data);
adminApi.addNewPayment = (id, data) => client.post(`invoices/${id}/add_payment`, data);
adminApi.createInvoice = (id, data) => client.post(`organisations/${id}/create_invoice`, data);
adminApi.recreateInvoicePdf = (id) => client.post(`invoices/${id}/recreate_pdf`);

//Support
adminApi.fetchSupportTickets = (params) => client.get(`tickets?${params}`);
adminApi.fetchSupportTicketByID = (id) => client.get(`tickets/${id}`);
adminApi.updateTicketStatus = (id, data) => client.patch(`tickets/${id}`, data);
adminApi.fetchGlobalComments = (params) => client.get(`tickets-global-comments`, params);

/**
 * Recreates the axios client instance using the current access tokens.
 *
 * This is called directly after the login action, as the instance is likely
 * to have nil credentials when the page first loads.
 */
adminApi.refreshAdminAuthToken = () => {
  console.log('Refreshing Keepspace API Admin Client Instance');

  headers = { ...HEADERS, ...adminAuthHeaders };
  client = new ApiService({ baseURL: BASE_URL, headers });
  clientGen = new ApiService({ baseURL: BASE_URL_GEN, headers });

  return headers;
};

adminApi.cleanAdminAuthToken = async () => {
  console.log('Cleaning Keepspace API Admin Client Instance');

  client = new ApiService({ baseURL: BASE_URL, defaultHeaders });
  clientGen = new ApiService({ baseURL: BASE_URL_GEN, defaultHeaders });
  return defaultHeaders;
};

window.adminApi = adminApi;

export default adminApi;
