const contactFields =
  [
    { value: 'name', label: 'Company Name *', required: ['required'], errorMessages: ['required'], options: [] },
    { value: 'display_name', label: 'Display name *', required: ['required'], errorMessages: ['required'], options: [] },
    { value: 'contact_name', label: 'Contact name *', required: ['required'], errorMessages: ['required'], options: [] },
    { value: 'phone', label: 'Phone', required: ['required', 'matchRegexp:^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$', 'minStringLength:8', 'maxStringLength:15'], errorMessages: ['required', 'Number is not valid', 'Number is not valid', 'Number is not valid'], options: [] },
    { value: 'email', label: 'Email address *', required: ['required', 'isEmail'], errorMessages: ['required', 'Email is not valid'], options: [] },
    { value: 'website', label: 'Website', required: [], options: [] },
  ]


export default contactFields
