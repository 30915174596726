import {
    FETCH_SUPPLIERS_BEGIN,
    FETCH_SUPPLIERS_SUCCESS,
    FETCH_SUPPLIERS_FAILURE,
    CREATE_SUPPLIER_BEGIN,
    CREATE_SUPPLIER_SUCCESS,
    CREATE_SUPPLIER_FAILURE,
    FETCH_SUPPLIER_BY_ID_BEGIN,
    FETCH_SUPPLIER_BY_ID_SUCCESS,
    FETCH_SUPPLIER_BY_ID_FAILURE,
    UPDATE_SUPPLIER_SUCCESS,
    UPDATE_SUPPLIER_FAILURE,
    DELETE_SUPPLIER_SUCCESS,
    DELETE_SUPPLIER_FAILURE
} from 'actions/supplierActions';


const initialState = {
    suppliers: [],
    loading: false,
    error: null,
    uploading: false,
}

export default function suppliersReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_SUPPLIERS_BEGIN:
            return  {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_SUPPLIERS_SUCCESS:
            return  {
                ...state,
                loading: false,
                suppliers: action.data
            };
        case FETCH_SUPPLIERS_FAILURE:
            return  {
                ...state,
                loading: false,
                error: action.payload.error,
                suppliers: []
            };
        case CREATE_SUPPLIER_BEGIN:
            return {
                ...state,
                uploading: true,
                error: null
            };
        case CREATE_SUPPLIER_SUCCESS:
            return {
                ...state,
                uploading: false,
                suppliers: [action.payload.supplier, ...state.suppliers ]
            };
        case CREATE_SUPPLIER_FAILURE:
            return {
                ...state,
                uploading: false,
                error: action.payload.error,
            };
         case FETCH_SUPPLIER_BY_ID_BEGIN:
            return {
                ...state,
                childLoading: true,
                error: null
            };

        case FETCH_SUPPLIER_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                supplier: action.payload.supplier
            };

        case FETCH_SUPPLIER_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case UPDATE_SUPPLIER_SUCCESS:
            let updatedSuppliers = state.suppliers.map(supplier => supplier.id === action.payload.supplier.id ?
               action.payload.supplier : supplier
            )
             return {
               ...state,
              supplier: action.payload.supplier,
              suppliers: updatedSuppliers
            }
        case UPDATE_SUPPLIER_FAILURE:
            return  {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case DELETE_SUPPLIER_SUCCESS:
          return  {
                ...state,
                loading: false,
                suppliers: state.suppliers.filter(supplier => supplier.id !== action.payload.id)
            };
        case DELETE_SUPPLIER_FAILURE:
          return  {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        default:
            return state
    }
}
