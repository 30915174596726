import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Button,
  colors
} from '@material-ui/core';

import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));



const CustomerEdit = props => {

  const {open, onClose, className, countries, handleSave, handleChange, values, fields, title, error, ...rest} = props;
  const classes = useStyles();
  let inputRef = React.createRef("form");

  if(!open) {
    return null;
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <ValidatorForm
          ref={inputRef}
          onSubmit={handleSave}
          onError={errors => console.log(errors)}
        >
          <CardContent>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
            >
              {title}
            </Typography>
            <Grid
              className={classes.container}
              container
              spacing={3}
            >
              {fields.map((column, i) => (
                <Grid
                  key={column.value}
                  item
                  md={6}
                  xs={12}
                >
                  <TextValidator
                    key={`${column.value}_${i}`}
                    className={classes.textField}
                    validators={column.required}
                    errorMessages={column.errorMessages}
                    id={column.value}
                    name={column.value || ""}
                    label={column.label}
                    fullWidth
                    value={values[column.value]}
                    onChange={handleChange}
                    variant="outlined"
                    SelectProps={{native: column.select}}
                    select={Boolean(column.select)}
                    error={props.error ? Boolean(props.error.response.data[column.value]) : false}
                    helperText={props.error ? props.error.response.data[column.value] : false}
                  >
                { column.options.map(option =>
                      <option
                        key={`${option[column.optionValue]}_${i}`}
                        value={option[column.optionValue]}
                      >
                        {option.text ? option.text : (option.text === "" ? "" : option)}
                      </option>
                    )}
                  </TextValidator>
                </Grid>
              ))}
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              onClick={onClose}
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
            >
              Save
            </Button>

          </CardActions>
        </ValidatorForm>
      </Card>
    </Modal>
  );
};

CustomerEdit.displayName = 'CustomerEdit';

CustomerEdit.propTypes = {
  className: PropTypes.string,
  values: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

CustomerEdit.defaultProps = {
  open: false,
  onClose: () => {}
};

export default CustomerEdit;





