import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { updateCustomer } from 'actions/customerActions';
import clsx from 'clsx';
import CustomerEdit from 'components/FormsPleaseStopDoingThis/CustomerForm/CustomerEdit';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import customerEditFormFields from './configs/customerEditFormFields';

import CustomerCard from './CustomerCard';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '750px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'none',
    },
  },
}));

const Overview = props => {
  const { className, customer, error, permittedToAccess, ...rest } = props;
  const classes = useStyles();
  const [openEdit, setOpenEdit] = useState(false);
  const [values, setValues] = useState({});
  const dispatch = useDispatch();
  const { countries } = useSelector(state => state.adminAuthReducer);

  useEffect(() => {
    setValues({ ...customer });
  }, [customer]);

  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleSave = (event) => {
    event.preventDefault();

    dispatch(updateCustomer(values))
      .then(result => {
        if (result) {
          // result is null when in error
          handleEditClose();
        }
      });
  };


  const handleChange = (event) => {
    // event.persist()
    const newValues = { ...values, [event.target.name]: event.target.value };
    setValues(newValues);
  };


  return (

    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={2}
      direction='column'
    >
      <Grid
        item
        lg={6}
        md={6}
        xl={6}
        xs={12}
      >
        <CustomerCard
          title='Customer Info'
          customer={customer}
          rows={['name', 'company_name', 'phone', 'email', 'customer_type']}
        />
      </Grid>

      <Grid
        item
        md={6}
        lg={6}
        xl={6}
        xs={12}
      >
        <CustomerCard
          title='Address Info'
          customer={customer}
          rows={['address_line_1', 'address_line_2', 'suburb', 'state', 'postcode', 'country']}
          edit={true}
          handleEditOpen={handleEditOpen}
          permittedToAccess={permittedToAccess}
        />
        {permittedToAccess('customers:edit') ?
          <CustomerEdit
            onClose={handleEditClose}
            open={openEdit}
            countries={countries}
            handleChange={handleChange}
            handleSave={handleSave}
            values={values}
            fields={customerEditFormFields({countries})}
            title='Edit Customer'
            error={error}
          /> : null}

      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        xl={6}
        xs={12}
      >
        <CustomerCard
          title='Value summary'
          customer={customer.details}
          rows={['orders', 'total_sold']}
          prefixes={['', '$']}
        />

      </Grid>
    </Grid>
  );
};

Overview.propTypes = {
  className: PropTypes.string,
};

export default Overview;
