const addressFields = ({countries}) => (
  [
    { value: 'address_line_1', label: 'Address 1 *', required: ['required'], errorMessages: ['required'], options: [] },
    { value: 'address_line_2', label: 'Address 2', required: [], options: [] },
    { value: 'suburb', label: 'Suburb *', required: ['required'], errorMessages: ['required'], options: [] },
    { value: 'postcode', label: 'Postcode *', required: ['required'], errorMessages: ['required'], options: [] },
    { value: 'state', label: 'State/Region *', required: ['required'], errorMessages: ['required'], options: [] },
    {
      value: 'country',
      label: 'Country *',
      required: ['required'],
      errorMessages: ['required'],
      options: countries,
      select: true,
      optionValue: 'text',
    },
  ]);


export default addressFields;
